const mobileHome = () => import('@/views/mobile/home/index.vue'); //首页
const mobileNavMenu = () => import('@/views/mobile/navMenu/index.vue'); //导航菜单
const mobilePatent = () => import('@/views/mobile/patent/index.vue'); //专利
const mobileBrandStory = () => import('@/views/mobile/brandStory/index.vue'); //品牌故事
const mobileBrandInfo = () => import('@/views/mobile/brandInfo/index.vue'); //品牌动态
const mobileBrandInfoSociety = () => import('@/views/mobile/brandInfoSociety/index.vue'); //社会责任
const mobileBrandInfoInnovate = () => import('@/views/mobile/brandInfoInnovate/index.vue'); //研发创新
const mobileSecurity = () => import('@/views/mobile/security/index.vue'); //防伪鉴别
const mobileJinghuashuangDetail = () => import('@/views/mobile/jinghuashuangDetail/index.vue'); //精华霜详情
const mobileJinghuayeDetail = () => import('@/views/mobile/jinghuayeDetail/index.vue'); //精华液详情
const mobileGegendanbaiDetail = () => import('@/views/mobile/gegendanbaiDetail/index.vue'); //葛根蛋白详情
const mobileShanshiYanwoZhiwuDetail = () => import('@/views/mobile/shanshiYanwoZhiwuDetail/index.vue'); //燕窝胶原多肽植物饮详情

export default [
  {
    path: '/mobile/home',
    component: mobileHome,
    name: 'mobileHome'
  },
  {
    path: '/mobile/navMenu',
    component: mobileNavMenu,
    name: 'mobileNavMenu'
  },
  {
    path: '/mobile/patent',
    component: mobilePatent,
    name: 'mobilePatent'
  },
  {
    path: '/mobile/brandStory',
    component: mobileBrandStory,
    name: 'mobileBrandStory'
  },
  {
    path: '/mobile/brandInfo',
    component: mobileBrandInfo,
    name: 'mobileBrandInfo'
  },
  {
    path: '/mobile/brandInfoSociety',
    component: mobileBrandInfoSociety,
    name: 'mobileBrandInfoSociety'
  },
  {
    path: '/mobile/brandInfoInnovate',
    component: mobileBrandInfoInnovate,
    name: 'mobileBrandInfoInnovate'
  },
  {
    path: '/mobile/security',
    component: mobileSecurity,
    name: 'mobileSecurity'
  },
  {
    path: '/mobile/jinghuashuangDetail',
    component: mobileJinghuashuangDetail,
    name: 'mobileJinghuashuangDetail'
  },
  {
    path: '/mobile/jinghuayeDetail',
    component: mobileJinghuayeDetail,
    name: 'mobileJinghuayeDetail'
  },
  {
    path: '/mobile/mobileGegendanbaiDetail',
    component: mobileGegendanbaiDetail,
    name: 'mobileGegendanbaiDetail'
  },
  {
    path: '/mobile/mobileShanshiYanwoZhiwuDetail',
    component: mobileShanshiYanwoZhiwuDetail,
    name: 'mobileShanshiYanwoZhiwuDetail'
  }
];
